<template>
  <div class="image-to-text-page">
    <div class="readable_content">
      <div class="heading-4 main-title">Upload image to extract and translate Chinese text.</div>
      <stand-out-block v-if="uploadImageState && uploadImageState.uploading">
        <div class="heading-5">Uploading... Do not close this page.</div>
        <uploader-file
          :file="uploadImageState"
          :progress="uploadImageState.progress"
          :is-completed="uploadImageState.progress >= 100"
          :is-in-progress="uploadImageState.uploading"
        ></uploader-file>
      </stand-out-block>
      <stand-out-block v-else>
        <drag-drop-prompt @drop="onDrop"></drag-drop-prompt>
        <div class="inner-block">
          <image-icon :size="32"></image-icon>
          <div class="text-lg icon-label">Choose an image:</div>
          <div class="buttons">
            <mcr-button class="white small" @click="uploadButtonClick">From Device</mcr-button>
            <mcr-button class="white small" v-if="allowLibrary" @click="selectFromLibraryClick"
              >From Photos & Files</mcr-button
            >
          </div>
          <div class="text-lg drop-files-message">Or drag & drop file here</div>
        </div>
      </stand-out-block>
      <image-to-text-input :ref="filesInputRef" @uploaded="onFileUploaded"></image-to-text-input>
    </div>
  </div>
</template>

<script>
import UploaderFile from '@common/elements/animations/uploaderFile';
import McrButton from '@common/elements/buttons/mcrButton';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import ImageIcon from 'vue-material-design-icons/Image';
import {mapGetters} from 'vuex';

import ImageToTextInput from './ImageToTextInput';

export default {
  props: {
    allowLibrary: {type: Boolean, default: false},
  },
  data() {
    return {
      filesInputRef: 'files-input',
    };
  },
  computed: {
    ...mapGetters(['uploadImageState', 'userFamilyTreeIdState']),
  },
  methods: {
    onDrop(files) {
      this.$refs[this.filesInputRef].filesSelected(files);
    },
    onFileUploaded() {
      if (this.allowLibrary) {
        const action = {text: 'View', push: {name: 'familytree-library', params: {id: this.userFamilyTreeIdState}}};
        this.$toasted.success('Image is added to Photos & Files.', {action});
      }
    },
    uploadButtonClick() {
      this.$refs[this.filesInputRef].$refs.input.click();
    },
    uploadButtonCameraClick() {
      this.$refs[this.filesInputCameraRef].$refs.input.click();
    },
    selectFromLibraryClick() {
      this.$emit('select-from-library');
    },
  },
  name: 'ImageToTextBasePage',
  components: {ImageToTextInput, DragDropPrompt, UploaderFile, McrButton, StandOutBlock, ImageIcon},
};
</script>

<style scoped lang="scss">
.image-to-text-page {
  .main-title {
    text-align: center;
  }

  .stand-out-block {
    margin-top: 32px;
    padding: 32px;

    .inner-block {
      border: 3px dashed $neutral-300;
      border-radius: 4px;
      background: $neutral-100;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 32px;

      .image-icon {
        color: $neutral-400;
      }
      .icon-label {
        color: $neutral-600;
      }
      .drop-files-message {
        color: $neutral-500;
      }
      .buttons {
        display: flex;
        gap: 16px;
        margin: 16px 0;
      }
    }

    .upload-link {
      display: none;
    }

    .uploader-file {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .readable_content {
      padding: 40px 0;
    }
    .main-title {
      margin: 0 16px;
    }
    .stand-out-block {
      margin-top: 24px;
      padding: 16px;

      .inner-block {
        border: none;
        padding: 0;
        background: transparent;
        .buttons {
          margin-top: 20px;
          flex-direction: column;
          min-width: 80%;
        }
        .drop-files-message {
          display: none;
        }
      }

      .uploader-file {
        margin-top: 24px;
      }
    }
  }
}
</style>
